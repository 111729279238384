import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import ImageDelay from "../components/ImageDelay"

const IndexPage = () => {
  return (
    <>
      <Layout pageTitle="Tusen takk!">
        <SEO title="Brøllup" />
        <section className="sm:flex sm:flex-row items-center"></section>

        <div className=" max-w-md m-auto">
          <p className="mb-2">Kjære familie og venner,</p>
          <p className="mb-2">
            Vi vil gjerne takke dere for alle gaver og bidrag til ønskelisten,
            og takke alle dere som hadde mulighet til å delta på vår store dag.
          </p>
          <p className="mb-2">Dagen ble bedre enn vi hadde turt å drømme om!</p>
          <p className="mb-2">
            Vi har endelig fått ut bildene og video av vielsen her på nettsiden,
            men for at alles personvern skal være ivaretatt, ligger bildene bak
            et passord. Dette får dere ved å sende en av oss en sms eller
            melding på Facebook.
          </p>
          <p className="mb-2">
            Vi gleder oss til neste gang vi kan se dere alle igjen!
          </p>
          <p className="mb-2">Store klemmer, Karoline og Thomas</p>
        </div>

        <StaticQuery
          query={graphql`
            query {
              desktop: file(relativePath: { eq: "forside.jpg" }) {
                childImageSharp {
                  fluid(quality: 70, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          `}
          render={(data) => {
            return (
              <div className="mt-4 mb-10 border-2 border-black">
                <ImageDelay
                  className="mx-auto sm:w-full max-w-screen-lg shadow-2xl"
                  fluid={data.desktop.childImageSharp.fluid}
                />
              </div>
            )
          }}
        />
      </Layout>
    </>
  )
}

export default IndexPage
